// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './portfolio.css';
// import Modal from './Modal'; // Import the Modal component

// const ProductsPortfolio = () => {
//   const [products, setProducts] = useState([]); // Store fetched products here
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedImage, setSelectedImage] = useState('');
//   const [visibleProductsCount, setVisibleProductsCount] = useState(3); // Start by showing 3 products
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(null); // Error state

//   // Fetch products from the backend API
//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get('https://uprightback.onrender.com/allproducts');

//         if (Array.isArray(response.data.data)) {
//           setProducts(response.data.data); // Set the products from the `data` field
//         } else {
//           throw new Error('Invalid product data format');
//         }

//         setLoading(false); // Set loading to false when data is fetched
//       } catch (err) {
//         console.error('Fetch error:', err);
//         setError('Failed to fetch products'); // Set error message if something goes wrong
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, []);

//   const openModal = (image) => {
//     setSelectedImage(image);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedImage('');
//   };

//   // Show more products when "View More" is clicked
//   const showMoreProducts = () => {
//     setVisibleProductsCount(products.length); // Display all products
//   };

//   if (loading) {
//     return <div>Loading products...</div>; // Show a loading message while data is being fetched
//   }

//   if (error) {
//     return <div>{error}</div>; // Show an error message if the fetch fails
//   }

//   return (
//     <div className="products-portfolio">
//       <section className="portfolio-intro">
//         <h1>Our Products & Portfolio</h1>
//         <p>
//           Explore some of our top-notch products and successfully delivered projects in the food processing and industrial sectors.
//         </p>
//       </section>

//       <section className="portfolio-items">
//         {products.slice(0, visibleProductsCount).map((product) => (
//           <div key={product._id} className="portfolio-item" onClick={() => openModal(product.image)}>
//             <img src={product.image} alt={product.name} className="product-image" />
//             <h3>{product.name}</h3>
//             <p>{product.description}</p>
//           </div>
//         ))}
//       </section>

//       {visibleProductsCount < products.length && (
//         <button className="view-more" onClick={showMoreProducts}>View More Products</button>
//       )}

//       <Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
//     </div>
//   );
// };

// export default ProductsPortfolio;
import React, { useState, useEffect } from "react";
 import './portfolio.css';

// Placeholder URL for your API endpoint
const API_URL = "https://uprightback.onrender.com/allproducts"; // Replace with your actual API endpoint

const ProductsPortfolio = () => {
  const [products, setProducts] = useState([]); // State to hold products from API
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [visibleProductsCount, setVisibleProductsCount] = useState(6); // Initial number of products to show

  // Fetch products da ta from the backend API
  useEffect(() => { 
    const fetchProducts = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        setProducts(data.data); // Assuming `data.data` contains the array of products
        setIsLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching products:", error);
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);

  // Function to load more products when "Load More" button is clicked
  const loadMoreProducts = () => {
    setVisibleProductsCount(visibleProductsCount + 6); // Load more products
  };

  if (isLoading) {
    // Return skeleton loaders while fetching products
    return (
      <div className="portfolio-container">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="skeleton" />
        ))}
      </div>
    );
  }

  return (
    <div className="portfolio-container">
      {products.slice(0, visibleProductsCount).map((product) => (
        <div key={product._id} className="portfolio-item">
          <div className="image-container">
            <img
              src={product.image} // Dynamically load the image from backend API
              alt={product.name}
              className="product-image"
              loading="lazy" // Lazy load the images for performance
            />
          </div>
          <h3>{product.name}</h3>
          <p>{product.description}</p>
        </div>
      ))}
      {visibleProductsCount < products.length && (
        <button onClick={loadMoreProducts}>View More</button>
      )}
    </div>
  );
};

export default ProductsPortfolio;

